export const DropDownContent={

    Expolre:"EXPLORE",
    Service:"SERVICES",
    Product:"PRODUCT",
    AboutUS:"ABOUT US",
    CurrentOpening:"CURRENT OPENING",
    Consulting:"COUNSULTING",
    AppDevelopment:"APP DEVELOPMENT",
    Training:"TRAINING",
    Consen:"CONSEN",
    Letsworktogether:"LET'S WORK TOGETHER +"


}



