import AppDevelopment from '../Assets/App Development.svg'
import Training from '../Assets/Training.svg'
import Counsulting from '../Assets/Counsulting.svg'

export const ServiceDetails = [
    {
        ServiceLogo: Counsulting,
        ServiceTitle: "Consulting",
        ServiceDesription: "Our consulting services involve a wide range of services like Business Intelligence, IBM DataStage, Oracle BI, Big Data Analytics, Data Science"
    },
    {
        ServiceLogo: AppDevelopment,
        ServiceTitle: "MobileAppDevelopment",
        ServiceDesription: "Agilewit Solutions is incorporated as a Technology Consulting services and Software product development firm. Head quartered in Texas and Offshore ..."
    },
    {
        ServiceLogo: Training,
        ServiceTitle: "Training",
        ServiceDesription: "With a motive to provide an opportunity to the IT as pirants, to learn the software technologies and tools from the experienced and expert group ...."
    },

]