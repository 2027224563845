export const ApplyConstant={
    FillApplication:"Fill Application",
    Experiance:"Experiance",
    Fresher:"Fresher",
    TwoYears:"0-2 Years",
    FourYears:"2-4Years",
    SixYears:"4-6Years",
    EightYears:"6-8Years",
    UploadResume:"Upload Resume",
    NoticePeriod:"Notice Period",
    ImmediateJointer:"Immediate Jointer",
    OneMonth:"1 Month",
    TwoMonth:"2 Month",
    ThreeMonth:"3 Month",
    BackToJobs:"Cancel",
    ContactWarning:"Please enter exactly 10 digits."


}