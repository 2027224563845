import ASP from '../Assets/ASP.svg'
import  Javas from '../Assets/Java.svg'
import RPA from '../Assets/RPA.svg'
import UI from '../Assets/UIDeveloper.svg'

export const TrainingInfo={
        TrainingTitle:"Our Trainings",
        TrainingDescription:"With a motive to provide an opportunity to the IT aspirants,to learn the software technologies and tools from the experienced and expert group of software professionals, Agilewit initiate to provide software training to the IT aspirants. the trainees get real industry exposure, which will help the trainees not only to learn the technologies but also become the experts of the technologies & tools.",
        TrainingArray:[ASP,Javas,RPA,UI]
    }
