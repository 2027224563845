import ContactUsforDesktop from './ContactUsforDesktop/ContactUsforDesktop'
import ContactUsforMobile from "./ContactUsforMobile/ContactUsforMobile"

const ContactUs=()=>{
    return(
        <div>
<ContactUsforDesktop/>
<ContactUsforMobile/>
        </div>
    )
}

export default ContactUs