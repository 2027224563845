export const JobContent={
    SearchJobs:"SearchJobs",
    AdvanceSearch:"Advance Search",
   PopulerSearch: "POPULAR SEARCHS",
   JobsTypes:["manufacturing & production","Software Engineer","engineering","information technology"],
   JobsCategory:"JOB CATEGORY",
   JobsCategoryArray:["manufacturing & production","Software Engineer","information technology"],
   volunteeropportunities:"Volunteer Opportunities",
   volunteeropportunitiesDescription:"Make a Difference Today! Join us in making a meaningful impact in our community. Whether you want to contribute a few hours a week or make a long-term commitment, we have a variety of opportunities that match your skills, interests, and availability",
   ViewJobs:"View Jobs"
}